import React from 'react';

import { SEO } from 'gatsby-theme-thepuzzlers-intl';
import { injectIntl } from 'gatsby-theme-thepuzzlers-intl';
import { Helmet } from 'react-helmet';
import { Box } from '@thepuzzlers/pieces';

// Local Components
import { Header, About, Question, Strategy } from 'sections/caseStudyPage';
import { Spacer } from 'components';

// Assets
import thumbnail from 'assets/seo/thumbnail-case-study-intrexx.jpg';

// Local Components
import { Suitability } from 'components/sections';

// Hooks
import { useKisCaseStudyPageData } from 'graphqlHooks';
import { useCaseStudyPageData } from 'sections/caseStudyPage';

const CaseStudy = () => {
  const { seo } = useCaseStudyPageData();

  const {
    header: cmsHeader,
    about: cmsAbout,
    question: cmsQuestion,
    passion: cmsPassion,
    strategy: cmsStrategy
  } = useKisCaseStudyPageData();

  return (
    <>
      <SEO {...seo} />
      <Helmet>
        {/* Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seo.url} />
        <meta property="og:image" content={thumbnail} />

        {/* Twitter */}
        <meta property="twitter:url" content={seo.url} />
        <meta property="twitter:image" content={thumbnail} />
      </Helmet>
      <Header data={cmsHeader} />
      <Spacer height={['16rem', null, '18rem', null, '20rem', '0rem']} />
      <Box
        as="main"
        sx={{
          overflow: 'hidden'
        }}>
        <About data={cmsAbout} />
        <Spacer
          height={['22.9rem', null, '22.4rem', null, '22rem', '23.2rem']}
        />
        <Question
          data={{ questionData: cmsQuestion, passionData: cmsPassion }}
        />
        <Spacer height={['20rem', null, '27rem', null, '30rem', '31.1rem']} />
        <Strategy data={cmsStrategy} />
        <Spacer
          height={['23.6rem', null, '31.8rem', null, '29rem', '32.8rem']}
        />
        <Suitability />
        <Spacer height={['20rem', null, '28rem', null, '26rem', '30rem']} />
      </Box>
    </>
  );
};

export default injectIntl(CaseStudy);
