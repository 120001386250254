import React from 'react';

// External Components
import { Section, Heading, Box } from '@thepuzzlers/pieces';
import { convertApiHtmlText } from 'graphqlHooks/helper';
import { AspectRatioImageContainer } from 'components';
import { floatingLaptop } from 'sections';
import { ListItemWithCheckMark } from 'components/ListItemWithCheckMark';

export const Strategy = ({ data: { headline, image, list } }) => {
  return (
    <Section id="case-study__strategy">
      <Headline headline={headline} />
      <List list={list} />
      <Illustration image={image} />
    </Section>
  );
};

const Headline = ({ headline }) => {
  return (
    <Heading
      as="h2"
      className="__headline"
      dangerouslySetInnerHTML={{ __html: convertApiHtmlText(headline.html) }}
      sx={{
        fontFamily: 'primary.normal',
        '& > span': {
          fontFamily: 'primary.bold'
        },
        lineHeight: 1.35,
        fontSize: ['2.8rem', null, '3.2rem', null, '4rem', null, '4rem'],
        gridColumn: [
          '1/ span 10',
          null,
          '1/ span 13',
          null,
          '1/ span 13',
          '3/ span 11'
        ]
      }}
    />
  );
};

const List = ({ list }) => {
  return (
    <Box
      as="ul"
      // animation values
      className="__strategy-list-container __list-item-with-check-mark-container"
      sx={{
        gridColumn: [
          '1/13',
          null,
          '1/ span 14',
          null,
          '1/ span 11',
          '3/ span 10'
        ],
        mt: ['3rem', null, '5rem', null, '5rem', '6rem'],
        listStyle: 'disc',
        pl: ['2.5rem', null, '2.6rem', null, '2.6rem', '2.7rem']
      }}>
      {list.map((data, index) => (
        <ListItemWithCheckMark text={data.text} key={index} index={index} />
      ))}
    </Box>
  );
};

const Illustration = ({ image: { alt, image } }) => {
  return (
    <Box
      className="__red-circle-background"
      sx={{
        position: 'relative',
        bg: 'orange300',
        borderRadius: '50%',
        position: 'relative',
        gridColumnEnd: [12, null, 25, null, 25, 23],
        justifySelf: 'end',
        width: ['25.4rem', null, '27.6rem', null, '34.7rem', '34.7rem'],
        mt: ['7rem', null, '3.4rem', null, '3.4rem', '-1.1rem'],
        gridRow: [null, null, '1/ span 2', null, '1/ span 2', '1/ span 2'],
        alignSelf: 'start'
      }}>
      {/* this box is needed because the orange background is not snapping to the grid or it has it's own size*/}
      <Box
        sx={{
          visibility: 'hidden',
          // this pt 100% to make aspect ratio 1/1
          pt: '100%'
        }}
      />

      <AspectRatioImageContainer
        variants={floatingLaptop}
        initial="initial"
        animate="animate"
        aspectRatios={1}
        src={image}
        alt={alt}
        sx={{
          position: 'absolute',
          top: ['3.6rem', null, '3.9rem', null, '4.9rem', '4.9rem'],
          right: ['4.1rem', null, '4.4rem', null, '5.6rem', '5.6rem'],
          width: ['22.4rem', null, '24.3rem', null, '30.6rem', '30.6rem']
        }}
      />
    </Box>
  );
};
