import React from 'react';

// External Components
import { Section, Box, Heading } from '@thepuzzlers/pieces';

// Local Components
import { AspectRatioImageContainer, GridSpacer } from 'components';
import { convertApiHtmlText } from 'graphqlHooks/helper';

export const Header = ({ data: { headline, image, title } }) => {
  return (
    <Section id="case-study__header" as="header">
      <TopSpacer />
      <HeadlineImage image={image} />
      <TitleAndHeadline title={title} headline={headline} />
    </Section>
  );
};

const HeadlineImage = ({ image: { alt, image } }) => {
  return (
    <AspectRatioImageContainer
      aspectRatios={1 / 1.41}
      src={image}
      alt={alt}
      sx={{
        gridColumn: [
          '1/13',
          null,
          '1/ span 14',
          null,
          '1/ span 10',
          '1/ span 10'
        ],
        mt: ['5rem', null, '7.9rem', null, '-1.7rem', '-5rem'],
        justifySelf: ['center', null, 'end', null, 'end', 'end'],
        width: ['36.8rem', null, '42.9rem', null, '42.9rem', '48.4rem'],
        mr: [null, null, '1.4rem', null, '1.7rem', '2.2rem'],
        gridRow: [null, null, 2, null, 'unset', 'unset']
      }}
    />
  );
};

const TitleAndHeadline = ({ title, headline }) => {
  return (
    <Box
      className="case-study__header"
      sx={{
        gridColumn: ['1/13', null, '11/25', null, '12/25', '11/ span 13'],
        width: [null, null, '41.3rem', null, 'auto', 'auto'],
        mt: ['3rem', null, 0, null, 0, 0],
        gridRow: [null, null, 2, null, 'unset', 'unset']
      }}>
      <Heading
        as="h1"
        sx={{
          fontFamily: 'body.normal',
          textTransform: 'uppercase',
          lineHeight: 1,
          letterSpacing: '0.05em',
          fontSize: ['1.4rem', null, '1.6rem', null, '1.8rem', '1.8rem']
        }}>
        {title}
      </Heading>
      <Heading
        as="h2"
        sx={{
          fontFamily: 'primary.bold',
          lineHeight: 1.25,
          fontSize: ['4.8rem', null, '5.6rem', null, '6.4rem', '7.2rem'],
          hyphens: ['auto', null, 'auto', null, 'unset', 'unset'],
          mt: ['2rem', null, '2.4rem', null, '2.4rem', '2.4rem'],
          '& > br': {
            display: [null, null, null, null, null, 'none']
          }
        }}
        dangerouslySetInnerHTML={{ __html: convertApiHtmlText(headline.html) }}
      />
    </Box>
  );
};

const TopSpacer = () => {
  return (
    <GridSpacer
      height={[null, null, '15.7rem', null, '22.7rem', '19.2rem']}
      sx={{
        display: ['none', null, 'block', null, 'block', 'block']
      }}
    />
  );
};
