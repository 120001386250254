import React from 'react';

// External Components
import { Section, Heading, Box } from '@thepuzzlers/pieces';
import { ExcitedMark, OpenQuote, SwirlLine } from 'components';
import { convertApiHtmlText } from 'graphqlHooks/helper';
import { Paragraph } from '@thepuzzlers/pieces/dist/thepuzzlers-pieces.cjs.prod';

export const Question = ({
  data: {
    questionData: { headline },
    passionData: { headline: passionHeadline, description, author }
  }
}) => {
  return (
    <Section id="case-study__question">
      <QuestionHeadline headline={headline} />
      <SolutionHeadlineWithDecoration headline={passionHeadline} />
      <Quote description={description} author={author} />
    </Section>
  );
};

const QuestionHeadline = ({ headline }) => {
  return (
    <Heading
      as="h2"
      sx={{
        fontFamily: 'primary.bold',
        lineHeight: 1,
        fontSize: ['10rem', null, '12rem', null, '13rem', '14rem'],
        gridColumn: [
          '1/ span 10',
          null,
          '1/ span 12',
          null,
          '1/ span 9',
          '3/ span 8'
        ],
        position: 'relative',
        alignSelf: 'start'
      }}>
      <Box as="span">{headline}</Box>
      {/* TODo: handle latter for the mark */}
      <ExcitedMark
        sx={{
          position: 'absolute',
          width: ['10rem', null, '10.1rem', null, '11.5rem', '12.3rem'],
          right: ['3.7rem', null, '9.5rem', null, '8.1rem', '10.4rem'],
          bottom: ['-4.6rem', null, '-4.1rem', null, '-4.6rem', '-5.3rem'],
          transform: 'rotate(15deg)'
        }}
      />
    </Heading>
  );
};

const SolutionHeadlineWithDecoration = ({ headline }) => {
  return (
    <Box
      className="__solution-headline-with-decoration"
      sx={{
        gridColumn: ['2/13', null, '13/25', null, '13/25', '13/ span 10'],
        mt: ['17.6rem', null, '33.5rem', null, '35.6rem', '38.1rem'],
        position: 'relative'
      }}>
      <Heading
        as="h3"
        className="__solution-headline"
        dangerouslySetInnerHTML={{ __html: convertApiHtmlText(headline.html) }}
        sx={{
          fontFamily: 'primary.normal',
          '& > span': {
            fontFamily: 'primary.bold'
          },
          lineHeight: 1.35,
          fontSize: ['3.2rem', null, '4rem', null, '4.8rem', '4.8rem']
        }}
      />
      <SwirlLine
        sx={{
          position: 'absolute',
          width: ['16.8rem', null, '16.8rem', null, '18.8rem', '18.8rem'],
          top: ['-9.6rem', null, '-12.8rem', null, '-15.3rem', '-15.9rem'],
          left: ['16.2rem', null, '9rem', null, '12.8rem', '12rem']
        }}
      />
    </Box>
  );
};

const Quote = ({ description, author }) => {
  return (
    <Box
      className="quote"
      sx={{
        gridColumn: ['1/13', null, '7/25', null, '7/25', '8/ span 15'],
        mt: ['18.6rem', null, '25.4rem', null, '30rem', '30rem'],
        position: 'relative'
      }}>
      <Paragraph
        className="__quote-text"
        as="q"
        dangerouslySetInnerHTML={{
          __html: convertApiHtmlText(description.html)
        }}
        sx={{
          fontFamily: 'primary.normal',
          '& > span': {
            fontFamily: 'primary.bold'
          },
          lineHeight: 1.75,
          fontSize: ['2rem', null, '2.8rem', null, '3.2rem', '3.2rem']
        }}
      />
      <Paragraph
        className="__quote-author"
        dangerouslySetInnerHTML={{
          __html: convertApiHtmlText(author.html)
        }}
        sx={{
          fontFamily: 'body.normal',
          '& > span': {
            fontFamily: 'body.boldItalic'
          },
          lineHeight: 1,
          fontSize: ['1.6rem', null, '1.8rem', null, '2rem', '2rem'],
          mt: ['2.4rem', null, '3rem', null, '4rem', '4rem']
        }}
      />
      <OpenQuote
        sx={{
          position: 'absolute',
          width: ['4.9rem', null, '7.8rem', null, '10.2rem', '10.2rem'],
          top: ['-6.8rem', null, '-3.2rem', null, '-3.2rem', '-3.2rem'],
          left: ['0rem', null, '-12rem', null, '-16.8rem', '-16.2rem'],
          zIndex: -1
        }}
      />
    </Box>
  );
};
